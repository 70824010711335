<template>
  <div class="detail-container">
    <div class="top">
      <h4 class="title">高耐盐性污水除油净水剂OW-11研制及应用</h4>
      <p class="a">王明宪;慎娜娜;刘莉;杨利;许海涛;吴伟龙</p>
      <p class="b">2015年 克拉玛依市天明化工有限责任公司 新疆维吾尔自治区</p>
    </div>
    <div class="bottom">
      <h2 class="title">专利介绍</h2>
      <div class="content">
        塔河油田是20世纪90年代末开发的新型稠油油田,该油田的油藏能量高,原油在井底的温度高达145℃,井口温度在80℃左右,稠油流动性好,因而,开采的井多为自喷井,其采出液中的乳化水为地层底水,矿化度高达2.5×105mg/L,塔河油田的含油污水矿化度如此之高,这在国内是十分罕见的,这给后续的油田污水处理回注带来了巨大的困难。塔河油田的生产用水资源十分短缺,随着油田的开发,注水量不断增大,必须充分地利用塔河油田采出液污水资源,但是,如若对油田污水未经处理直接回注,将造成对油层的严重伤害;外排又将对环境造成严重污染,同时对原本水资源奇缺的塔河油田造成更大的损失和浪费。
        国内目前常规的含油污水除油净水剂属于聚丙烯酰胺改性类产品,主要是针对于低矿化度(含盐量在10000mg/L以下)水质条件下的污水除油净化技术产品。在矿化度高达2.5×105mg/L的塔河油田污水特性下,开展水质净化处理工作,通常的高分子絮凝剂极易产生盐析现象,失去絮凝净水效果,难以解决该油田高矿化度污水水质絮凝净化问题。
        高耐盐性含油污水除油净水剂OW-11(以下简称“除油净水剂OW-11”)产品在其分子结构中有规则地引入一定量的季铵盐型阳离子官能团,由于其分子内的的静电斥力使其分子链在高矿化度污水体系中充分地舒展,有效地解决了高矿化度污水体系对高分子絮凝剂的盐析难题,使其对高矿化度的污水处理达到良好的絮凝效果。同时,解决含油污水乳状液(O/W)破乳除油问题,使得回收的污油絮体杂质少,回掺到原油处理系统,对系统污染冲击小,生产平稳。该项目产品具有良好的破乳、除油、去悬浮物、脱色等功能,非常适用于油田、石化、城市工业及生活污水的处理,应用前景十分广泛,社会效益和经济效益十分显著。
        该项目研究的技术创新点主要体现在以下三个方面：
        (1)提出将原油破乳技术与高分子絮凝技术相结合的研究方法,提高了除油净水剂OW-11产品的破乳除油速度和除油效率。
        该方法是将开发出的水包油(O/W)型非离子三嵌段聚醚破乳剂技术引入除油净水剂OW-11产品中。由于非离子聚醚表面活性剂具有优良的耐盐性、破乳性能,再加上同阳离子聚合物絮凝复配协同效应,提高了除油净水剂OW-11产品的破乳除油速度和除油效率,破乳除油速度提高了2倍,除油率达到90%以上。
        申报发明专利1项,专利号：201110216329.8,专利名称：一种高矿化度稠油污水除油净水剂的制备方法。
        (2)通过设计聚合物高分子结构,提高了除油净水剂OW-11产品的耐盐性,解决了高矿化度污水体系对高分子絮凝剂的盐析难题。
        聚合物高分子结构的设计是在聚合物高分子链中引入一定量的季铵盐型阳离子官能团,使得聚合物分子链在高矿化度的污水中能够达到充分地舒展,克服了高矿化度污水对聚合物产生的盐析现象,提高了聚合物的耐盐性能。该产品成功地应用于高矿化度达250000mg/L的塔里木油田,污水除油净水效果十分显著。
        申报新疆维吾尔自治区新产品鉴定1项,证书编号：新经信技鉴字[2012]6号,产品名称：高矿化度含油污水除油净水剂OW-11。
        (3)通过调节聚合物高分子合成中单体的比例,设计出合理的聚合物分子量和聚合阳离子度,达到通过高分子聚合物调节絮体比重大小的目的,以满足现场生产过程中对絮体物采取沉降或者上浮不同排出方式的要求。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "patentDetail",
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.detail-container {
  background: #fff;
  padding: 30px 10px;
  .top {
    padding: 20px 30px;
    box-shadow: 0 0 4px #888888;
  }
  .bottom {
    margin-top: 50px;
    .title {
      width: 180px;
      padding: 6px 10px;
      color: #fff;
      border-radius: 5px;
      background: #1890ff;
    }
    .content {
      margin-top: 15px;
      padding: 20px;
      box-shadow: 0 0 4px #888888;
    }
  }
}
</style>
